import { ITimeNames } from './model';

const getId = (url: string): string => {
  const newUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  return newUrl[2] ? newUrl[2].split(/[^0-9a-z_-]/i)[0] : newUrl[0];
};

const sToTime = (s: number, names: ITimeNames): string => {
  const minutes = Math.floor(s / 60);
  const seconds = s % 60;

  return s
    ? `${minutes} ${names.min ? names.min : 'm'} ${seconds} ${names.sec ? names.sec : 's'}`
    : '';
};

export { getId, sToTime };
